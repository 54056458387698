@font-face {
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: normal;
  src: local('geomanistregular'), url('/fonts/geomanist-regular-webfont.woff') format('woff');
  font-display: swap
}

@font-face {
  font-family: 'Pier Sans Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Pier Sans Bold'), url('/fonts/PierSans-Bold.woff') format('woff');
  font-display: swap
}

@font-face {
  font-family: 'Pier Sans Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Pier Sans Regular'), url('/fonts/PierSans-Regular.woff') format('woff');
  font-display: swap
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@mixin width-section {
  width: 65vw;
  @media screen and (max-width: 1440px) {
    width: 75vw;
  }
  @media screen and (max-width: 1024px) {
    width: 80vw;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
  }
}

@mixin navbar {

  #primary-navbar {
    mix-blend-mode: difference;
    @include bleeds-right;
  }
  #secondary-navbar, #primary-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 45px;
    padding-bottom: 45px;


    .burger-menu-btn {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &-content {

        .line {
          width: 30px;
          height: 2px;
          background-color: #ffffff;
          display: block;
          margin: 6px auto;
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;

        }
      }
    }

    .is-active {
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      -webkit-transition-delay: 0.6s;
      -o-transition-delay: 0.6s;
      transition-delay: 0.6s;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);

      .burger-menu-btn-content {
        .line:nth-child(2) {
          width: 0;
        }

        .line:nth-child(1), .line:nth-child(3) {
          -webkit-transition-delay: 0.3s;
          -o-transition-delay: 0.3s;
          transition-delay: 0.3s;
        }

        .line:nth-child(1) {
          -webkit-transform: translateY(8px);
          -ms-transform: translateY(8px);
          -o-transform: translateY(8px);
          transform: translateY(8px);
        }

        .line:nth-child(3) {
          -webkit-transform: translateY(-8px) rotate(90deg);
          -ms-transform: translateY(-8px) rotate(90deg);
          -o-transform: translateY(-8px) rotate(90deg);
          transform: translateY(-8px) rotate(90deg);
        }
      }
    }

    ul {
      display: flex;

      .nav-link, .mobile-nav-link {
        list-style: none;
        overflow: hidden;

        span {
          display: block;

          a {
            text-decoration: none;
            color: #FDFDFD;
            margin-left: 15vw;
            font-family: "Pier Sans Regular", sans-serif;
            letter-spacing: 3px;
            font-size: 1.4rem;
            cursor: pointer;
            position: relative;

            span {
              display: inline-block;
            }

            .second-word {
              position: absolute;
              left: 0;

            }
          }
        }
      }
    }


    span {
      a:nth-child(3) {
        margin-right: 200px;
      }
    }
  }
}

@mixin bleeds {
  padding-left: 50px;
  padding-right: 50px;
  margin-left: 50px;
  margin-right: 50px;

  @media screen and (max-width: 1600px) {
    padding-left: 50px;
    padding-right: 50px;
    margin-left: 50px;
    margin-right: 50px;
  }
  @media screen and (max-width: 1366px) {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 25px;
    margin-right: 25px;
  }
  @media screen and (max-width: 999px) {
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 20px;
    margin-right: 20px;
  }
}

@mixin bleeds-right {
  padding-left: 0;
  padding-right: 50px;
  margin-left: 0;
  margin-right: 50px;

  @media screen and (max-width: 1600px) {
    padding-left: 0;
    padding-right: 50px;
    margin-left: 0;
    margin-right: 50px;
  }
  @media screen and (max-width: 1366px) {
    padding-left: 0;
    padding-right: 10px;
    margin-left: 0;
    margin-right: 25px;
  }
  @media screen and (max-width: 999px) {
    padding-left: 0;
    padding-right: 5px;
    margin-left: 0;
    margin-right: 20px;
  }
}


@mixin menu-overlay {

  .mobile-menu-overlay-container {
    margin-top: 75px;
  }

  .menu-overlay, .mobile-menu-overlay-container {
    width: 100%;
    height: 100vh;
    z-index: 10;
    position: relative;
    display: flex;

    .nav-text, .mobile-nav-text {
      position: relative;
      top: 0;
      width: 70%;
      height: 100vh;
      background-color: #1E1E1E;
      z-index: 10;
      padding-top: 3rem;

      ul {
        text-align: center;
        counter-reset: menucounter;

        .nav-link, .mobile-nav-link {
          list-style: none;
          overflow: hidden;
          margin-bottom: 30px;

          span {
            display: block;

            a {
              text-decoration: none;
              color: #FDFDFD;
              font-size: 4rem;
              letter-spacing: 1px;
              cursor: pointer;
              position: relative;

              span {
                display: inline-block;
              }

              .second-word {
                position: absolute;
                left: 0;

              }
            }
          }
        }

        .nav-link:before, .mobile-nav-link:before {
          counter-increment: menucounter;
          content: counters(menucounter, '.', decimal-leading-zero);


          font-size: 1rem;
        }

        .nav-link.active, .mobile-nav-link.active {
          opacity: 1;
          transition: 0.5s;
        }
      }
    }

    .nav-text.active, .mobile-nav-text.active {
      left: 0;
      transition: 0.5s;
    }

    .nav-deco, .mobile-nav-deco {
      z-index: 9;
      background-color: #FDFDFD;
      width: 30%;
      position: relative;
      height: 100vh;
      top: 0;
    }

    .nav-deco.active, .mobile-nav-deco.active {
      left: 50%;
      transition: 0.5s;
    }
  }
}

html {
  font-size: 17px;
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
}

body {
  background-color: #171017;
  color: #ffffff;
  font-family: "Pier Sans Bold", sans-serif, Arial;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  //cursor: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMwLjUgMTZDMzAuNSAyNC4wMDgxIDI0LjAwODEgMzAuNSAxNiAzMC41QzcuOTkxODcgMzAuNSAxLjUgMjQuMDA4MSAxLjUgMTZDMS41IDcuOTkxODcgNy45OTE4NyAxLjUgMTYgMS41QzI0LjAwODEgMS41IDMwLjUgNy45OTE4NyAzMC41IDE2WiIgc3Ryb2tlPSJibGFjayIvPgo8cGF0aCBkPSJNNiA5QzcuNjU2ODUgOSA5IDcuNjU2ODUgOSA2QzkgNC4zNDMxNSA3LjY1Njg1IDMgNiAzQzQuMzQzMTUgMyAzIDQuMzQzMTUgMyA2QzMgNy42NTY4NSA0LjM0MzE1IDkgNiA5WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg=="),
  //  auto;
}

.loader-container {
  position: fixed;
  z-index: 1000000;
  background: #171017;
  height: 100vh;
  width: 100vw;
  top: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  //transition: opacity 1s;

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    img {
      width: 200px;
      height: 200px;

    }
  }

}

h1, h2 {
  font-size: 7rem;
  line-height: 7.9rem;
  font-weight: normal;
  margin-top: 0;
  text-transform: uppercase;
  margin-bottom: 0;
  letter-spacing: 2px;
}

.underscore-white {
  color: #FDFDFD;
  text-decoration: underline;
}


.canvas-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  #c {
    width: 100%;
    height: 100%;
    display: block;
  }
}


.mobile {
  visibility: hidden;
}


.mobile-menu-overlay {
  position: fixed;
  @include menu-overlay;
  width: 100vw;
}

#mobile-nav {
  position: fixed;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #1E1E1E;
  width: 100%;
  z-index: 1000;

  .mobile-nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1E1E1E;
    @include bleeds;

    a {
      text-decoration: none;
      color: #FDFDFD;
      font-size: 1.2rem;
      letter-spacing: 1px;
    }

    .mobile-nav-left {
      display: flex;
      align-items: center;

      a {
        margin-left: 50px;
      }
    }

    .menu-toggle, .menu-toggle-mobile {
      width: 40px;
      height: 30px;
      cursor: pointer;
      transition: 0.5s;

      div {
        width: 40px;
        height: 3px;
        background-color: #FDFDFD;
        border-radius: 2px;
      }

      div:nth-child(2) {
        transform: translateY(8px);

      }

      div:nth-child(3) {
        width: 20px;
        height: 3px;
        background-color: #8A8E9A;
        transform: translateY(16px);
        border-radius: 2px;
      }
    }
  }
}

.site-header {
  z-index: 999;
  // position: relative;
  position: fixed;
  right: 0;
  mix-blend-mode: difference;
  @include navbar;
  opacity: 0;
}


.main-container {
  font-size: 1.2rem;
  position: relative;
  overflow-x: hidden;

  .hero-section, .aboutus-section, .services-section, .realizations-section, .contactus-section {
    @include bleeds;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
    position: relative;

    &-text {
      h1,h2 {
        // overflow: hidden;
        will-change: transform;
        padding: 10px 0;

        .letter {
          display: inline-block;
          -webkit-font-smoothing: antialiased;
        }

        .animation {
          display: block;
        }

        br {
          line-height: 0;
        }

      }

      p {
        font-size: 1.3rem;
        line-height: 1.88rem;
        font-family: geomanistregular, sans-serif;
        color: #ffffff;
      }

    }


    &-call {
      margin-top: 5.88rem;
      letter-spacing: 1px;
    }
  }

  .panel {
    margin-top: 9.7rem;
  }

  .hero-section {
    position: relative;
    top: 0;
    height: calc(100vh);
    @include navbar;

    &-text {

      h1 {
        margin-top: 3.76rem;
        width: 100%;

        .services-big-text {
          display: none;
        }
      }

      .animation {
        overflow: hidden;
      }

      .animation:nth-child(2) {
        // margin-top: -30px;
        margin-left: 14.7rem;
      }
    }

    &-bottom {

      display: flex;
      justify-content: flex-end;
      margin-bottom: 2.94rem;

      p {
        font-size: 1.3rem;
        line-height: 1.88rem;
        font-family: geomanistregular, sans-serif;
        color: #ffffff;
      }
    }
  }

  .aboutus-section {
    position: relative;

    &-text {

      h2 {
        .animation:nth-child(2) {
          text-align: right;
        }
      }

      &-middle {
        @include width-section;
        text-transform: uppercase;
        font-family: "Pier Sans Regular", sans-serif;
        font-size: 2.35rem;
        margin: auto;
        margin-top: 5.88rem;
        margin-bottom: 8.7rem;
        text-align: justify;
        line-height: 2.9rem;
      }
    }

    .text-cta-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include width-section;
      margin: auto;

      .aboutus-section-text-body {
        width: 50%;

        p {
          text-align: justify;
        }
      }

    }
  }

  .services-section {
    padding-top: 3.8rem;

    &-text {
      h2 {
        .animation:nth-child(2) {
          text-align: right;
        }
      }
    }

    &-text-body {
      margin: auto;
      @include width-section;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 5.88rem;

      &-left {
        flex: 1;

        &-top {
          p {
            text-align: justify;
            width: 90%;
          }
        }

        .fancy-button {
          margin-top: 5.88rem;

          svg {
            cursor: pointer;

            .pink-circle {
              transform: scale(0);
              transition: .3s cubic-bezier(.215, .61, .355, 1);
              transform-origin: center;
            }


            &:hover {
              .pink-circle {
                transform: scale(1);
              }

              .pupil {
                fill: #D5219E;
              }
            }
          }
        }
      }

      &-right {
        display: flex;
        flex-direction: column;
        flex: 1;
        counter-reset: servicescounter;

        span {
          font-family: "Pier Sans Regular", sans-serif;
          font-size: 2.35rem;
          color: #ffffff;
          line-height: 2.9rem;
          text-transform: uppercase;
          margin-bottom: 3.5rem;
        }

        span:before {
          counter-increment: servicescounter;
          content: counters(servicescounter, '.', decimal-leading-zero);
          margin-right: 20px;
          font-size: 1.2rem;
          font-family: geomanistregular, sans-serif;
        }
      }
    }


  }

  .realizations-section {
    padding-top: 3.8rem;
    padding-right: 0;
    margin-right: 0;
    padding-bottom: 9.7rem;

    &-text {
      &-body {
        position: relative;
        margin-top: 5.88rem;

        &-left {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(0, -50%);
          //mix-blend-mode: difference;

          .realizations-buttons-left {
            margin-top: 2.94rem;
          }

          &-container {
            .project-scope {
              font-family: geomanistregular, sans-serif;
              font-size: 1.2rem;
            }

            .overlay-text {
              position: relative;

              h3 {
                font-family: geomanistregular, sans-serif;
                font-size: 5.3rem;
                color: #ffffff;
                line-height: 5.88rem;
                text-transform: uppercase;
              }
            }
          }
        }

        &-right {
          text-align: right;

          &-img {
            display: flex;
            justify-content: flex-end;

            //.over {
            //  position: relative;
            //  overflow: hidden;
            //  width: 60vw;
            //  height: 550px;

              img {
                //position: absolute;
                width: 60vw;
                height: 100%;
                //right: 0;
              }
           // }

          }
        }
      }
    }
  }

}

.fancy-button, .small-fancy-button {

  svg {
    cursor: pointer;

    .pink-circle {
      transform: scale(0);
      transition: .3s cubic-bezier(.215, .61, .355, 1);
      transform-origin: center;
    }

    &:hover {
      .pink-circle {
        transform: scale(1);
      }

      .pupil {
        fill: #D5219E;
      }
    }
  }
}

footer {
  z-index: 5;
  background: #EDE1ED;
  position: relative;
  color: #171017;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;

  .contactus-section {
    padding-top: 3rem;
    @include bleeds;

    &-text {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }

    h2 {
      font-size: 5.8rem;
      line-height: 7rem;
      display: flex;
      flex-direction: column;

      .services-big-text {
        display: none;

      }

      .letter {
        display: inline-block;
      }
    }

    &-text-middle {
      @include width-section;
      text-transform: uppercase;
      font-family: "Pier Sans Regular", sans-serif;
      font-size: 2.35rem;
      margin: auto;
      margin-top: 4.7rem;
      margin-bottom: 5.88rem;
      text-align: justify;
      line-height: 2.9rem;
    }

    .text-cta-container {
      margin: auto;
      //margin-top: 4%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include width-section;

      .contactus-section-text {

        &-body {
          flex: 1;
          display: flex;
          align-items: center;

          .contact {
            flex: 1;
            border-right: 1px solid #171017;
            padding-right: 5vw;

            a:nth-child(1) {
              margin-bottom: 1.26rem;
            }

            a {
              font-size: 2.5rem;
              line-height: 2.5rem;
              font-family: geomanistregular, sans-serif;
              display: flex;
              align-items: center;
              text-decoration: none;
              color: #171017;

              span {
                .small {
                  font-size: 2.5rem;
                }
              }

              img {
                margin-right: 20px;
              }
            }
          }

          .social {
            flex: 1;
            //            justify-content: flex-end;
            //display: flex;
            padding-left: 5vw;

          }
        }
      }

      .fancy-button {
        flex: 1;
        justify-content: flex-end;
        display: flex;
      }
    }
  }

  .footer-copyrights {
    @include bleeds;
    padding-top: 50px;
    padding-bottom: 50px;
    border-top: 2px solid #E5E5E5;
    position: relative;
    z-index: 10;
    font-size: 0.9rem;
    display: flex;
    justify-content: space-between;
    font-family: "geomanistregular", sans-serif;

    a {
      text-decoration: none;
      color: #171017;
    }
  }
}


.btn {
  display: inline-block;
  color: #ffffff;
  padding: 55px;
  position: relative;
  letter-spacing: 1px;

  &__circle,
  &__text,
  &__white-circle {
    position: absolute;
  }

  &__circle {
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 100%;
    width: 100%;
    border: 1px solid #BBBEC9;
    transition: 0.3s linear;
  }

  &__white-circle {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 110px;
    height: 110px;
    border-radius: 100%;
    background: #D5219E;
    display: flex;
    transition: 0.3s ease-in-out;

    svg {
      width: 25px;
      height: 25px;
      margin: auto;
      fill: #ffffff;
    }
  }

  &__text {
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
    z-index: 2;
    padding: 50px 8px;
    transition: 0.3s linear;
    font-size: 1.2rem;
    font-family: "Pier Sans Bold", sans-serif;
  }

  &:hover {

    .btn__white-circle {
      transform: translate(-50%, -50%) scale(1.03);
    }

    .btn__text {
      transform: translate(70px, -50%);
    }
  }
}

@media screen and (max-width: 1680px) {

  html {
    font-size: 16px;
  }

}

@media screen and (max-width: 1440px) {

  html {
    font-size: 14px;
  }

  .fancy-button {
    svg {
      width: 190px;
    }
  }
  footer {

    .footer-copyrights {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

@media screen and (max-width: 1366px) {
  .fancy-button {
    svg {
      width: 170px;
    }
  }
  .main-container {
    .realizations-section-text {
      &-body-right-img {
        //.over {
          img {
            width: 55vw;
          }
        //}

      }
    }

  }
  .btn {
    padding: 40px;
  }
  .btn__white-circle {
    width: 80px;
    height: 80px;
  }
  .btn:hover {
    .btn__text {
      transform: translate(55px, -50%);
    }
  }
}

@media screen and (max-width: 1280px) {
  html {
    font-size: 12px;
  }
}

@media screen and (max-width: 1024px) {
  html {
    font-size: 11px;
  }
}

@media screen and (max-width: 900px) {
  html {
    font-size: 10px;
  }

  .site-header {
    #secondary-navbar {


      ul {
        display: none;
      }
    }
  }
  .main-container {

    .hero-section {
      #secondary-navbar {

        align-items: flex-start;
        padding-bottom: 5px;
        padding-top: 25px;

        ul {
          flex-direction: column;
          text-align: right;

          .nav-link {
            margin-bottom: 12px;
            font-size: 1.7rem;

            span {
              a {
                margin-right: 0;
                margin-left: 0;
                font-size: 1.7rem;
              }
            }
          }
        }
      }
    }

  }
  footer {
    .contactus-section {
      margin-top: 6rem;

    }
  }
}

.overlay-menu {
  z-index: 200;
  overflow: hidden;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  content: '';
  width: 100vw;
  height: 100vh;
  background: #171017;
  transition: all 500ms ease-in-out;

  // that's all the pen about
  clip-path: circle(15px at calc(100% - 65px) 65px);
  // for AT
  visibility: hidden;

  &-inner {
    @include bleeds;
    position: absolute;
    z-index: 200;
    overflow: hidden;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    // transform:translateX(-90%);
    transition: transform .7s cubic-bezier(.215, .61, .355, 1);
    display: flex;
    overflow-y: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    justify-content: space-between;
    flex-direction: column;

    ul {
      display: flex;
      flex-direction: column;

      .nav-link, .mobile-nav-link {
        list-style: none;
        overflow: hidden;
        margin-bottom: 1.17rem;

        span {
          display: block;

          a {
            text-decoration: none;
            color: #FDFDFD;
            // margin-right: 300px;
            font-family: "Pier Sans Regular", sans-serif;
            letter-spacing: 3px;
            font-size: 3.4rem;
            cursor: pointer;
            position: relative;

            span {
              display: inline-block;
            }

            .first-word {
            }

            .second-word {
              position: absolute;
              left: 0;
              //font-family: geomanistregular, sans-serif;
            }
          }
        }
      }
    }

    .contact {
      align-self: flex-end;

      a {
        font-size: 2rem;
        line-height: 2rem;
        font-family: geomanistregular, sans-serif;
        margin-bottom: 1.76rem;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #ffffff;

        span {
          .small {
            font-size: 1.6rem;
          }
        }


        img {
          margin-right: 20px;
        }
      }
    }

    span {
      a {
        text-decoration: none;
        color: #FDFDFD;
        //margin-right: 300px;
        font-family: "Pier Sans Regular", sans-serif;
        letter-spacing: 3px;
        font-size: 1.4rem;
        cursor: pointer;
      }

      a:nth-child(3) {
        margin-right: 200px;
      }
    }

    .overlay-menu-class {
      flex: 1;

      > ul {
        > li {
          border-bottom: 1px solid #fff;
          display: inline-block;
        }
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        color: #fff;
        font-size: 1.8rem;
        position: relative;
        display: flex;
        flex-direction: column;
        font-weight: 700;


        a {
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
}

.open-overlay {
  visibility: visible;

  clip-path: circle(100%);

}

@media screen and (max-width: 720px) {

  .fancy-button {
    svg {
      width: 160px;
      height: 160px;
    }
  }
  .btn {
    padding: 30px;
  }
  .btn__white-circle {
    width: 60px;
    height: 60px;
  }
  .btn:hover {
    .btn__text {
      transform: translate(45px, -50%);
    }
  }
  .main-container {
    .aboutus-section {
      .text-cta-container {
        flex-direction: row-reverse;

        .aboutus-section-text-body {
          width: 60%;
        }
      }
    }

    .services-section-text-body {
      flex-direction: column;

      &-left {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .fancy-button {
          margin-top: 0;
        }

        &-top {
          width: 60%;

          p {
            width: 100%;
          }
        }
      }

      &-right {
        margin-top: 2rem;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .overlay-menu {
    &-inner {
      .contact {
        a {
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
  .site-header {
    #primary-navbar {
      padding-top: 25px;
      padding-bottom: 5px;
    }
  }
  h1, h2 {
    font-size: 5.8rem;
    line-height: 7rem;
  }

  .main-container {
    .hero-section, .aboutus-section, .services-section, .realizations-section {
      padding-top: 0;

      &-text {
        &-middle {
          font-size: 2.15rem;
          line-height: 2.8rem;
        }

        p {
          font-size: 1.8rem;
          line-height: 2.5rem;
        }
      }
    }

    .hero-section {

      &-text {

        h1 {
          font-size: 5.76rem;
          line-height: 7.1rem;
          margin-top: 0;

          .desktop {
            display: none;
          }

          .animation:nth-child(2) {
            // margin-bottom: 4rem;
            margin-left: 0;
            text-align: right;
          }

          .services-big-text {
            //font-size: 5.2rem;
            -webkit-text-stroke: 1px white;
            color: transparent;
            display: flex;

            .anim {
              padding: 0 10px;
            }
          }

          .animation:nth-child(4) {
            text-align: right;
          }
        }
      }

      &-bottom {
        justify-content: flex-start;
        margin-bottom: 12rem;

        p {
          font-size: 1.8rem;
          line-height: 2.5rem;
          width: 100%;
        }
      }

    }

    .panel {
      margin-top: 0.7rem;
    }

    .aboutus-section {
      &-text-middle {
        margin-top: 2.3rem;
        margin-bottom: 2.3rem;
      }

      .text-cta-container {
        flex-direction: column;

        a {
          margin-top: 4rem;
          align-self: flex-end;
        }

        .aboutus-section-text-body {
          width: 100%;
          margin-top: 2.3rem;
        }
      }
    }

    .services-section-text {
      position: relative;

      &-body {
        flex-direction: column;
        margin-top: 2.88rem;
        margin-bottom: 4.88rem;

        &-left {
          display: flex;
          justify-content: space-between;
          align-items: center;

          a {
            display: none;
            position: absolute;
            right: 0;
            bottom: 0;

            .fancy-button {
              margin-top: 0;
            }
          }

          &-top {
            width: 100%;
            margin-bottom: 2rem;

            p {
              width: 100%;
            }
          }
        }

        &-right {
          margin-top: 3rem;

          span {
            margin-bottom: 2rem;
          }
        }
      }
    }

    .realizations-section {
      padding-bottom: 4.7rem;
      @include bleeds;
    }

    .realizations-section-text-body-left {
      position: relative;

      &-container {
        .project-scope {
          margin-bottom: 10px;
        }

        .overlay-text {
          h3 {
            font-size: 3.9rem;
            line-height: 3rem;
          }
        }

      }
    }

    .realizations-section-text-body-right {
      &-img {
        //.over {
          img {
            width: 100%;
          }
        //}

      }
    }

  }
  footer {
    .contactus-section {
      h2 {
        .desktop {
          display: none;
        }

        .services-big-text {
          -webkit-text-stroke: 1px #171017;;
          color: transparent;
          display: flex;

          .anim {
            padding: 0 10px;

          }
        }
      }

      &-text-middle {
        font-size: 2.15rem;
        line-height: 2.8rem;
      }

      .text-cta-container {
        flex-direction: column;
        align-items: flex-start;

        .fancy-button {
          align-self: flex-end;
          margin-top: 10.35rem;
          margin-bottom: 2.35rem;
        }

        .contactus-section-text-body {
          .contact {
            a {
              img {
                margin-right: 10px;
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }
    }

    .footer-copyrights {
      font-size: 1.25rem;
    }
  }
}

@media screen and (max-width: 394px) {
  html {
    font-size: 8px;
  }
}

@media screen and (max-width: 350px) {
  html {
    font-size: 7px;
  }
}

@media screen and (max-width: 310px) {
  footer {
    .contactus-section {
      &-text {
        .text-cta-container {
          .contactus-section-text-body {
            .social {
              svg {
                width: 45px;
              }
            }
          }
        }
      }
    }
  }
}
